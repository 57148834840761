import { css } from 'goober'
import { hc } from 'hono/client'
import { createResource } from 'solid-js'
import { Div } from '~/components/html-living-standard'
import { merge } from '~/utils/css-shorthand'
import 'devices.css'

type Props = ComponentProps<'div'> & { imageId: string, scale: number, top: number, width: number, y: number }

export function SmartphoneCF(props: Props) {
  const [src] = createResource(async () => {
    const api = hc<APIRoutes>(import.meta.env.VITE_SCHNAVI_API_ROOT_URL)
    const res = await api.images.$post({ json: { imageId: props.imageId } })
    const { src } = await res.json()
    return src
  })
  return <Presenter src={src} {...props} />
}

type PresenterProps = Props & { src: Resource<string> }

function Presenter({ src, y, width, scale, top }: PresenterProps) {
  const Style = {
    Block: {
      container: css({
        '.device-frame .device-screen': {
          backgroundColor: '#fff'
        },
        '.device-screen img': {
          borderRadius: 'inherit'
        },
        left: `${((scale - 1) * 428) / 2}px`,
        top: `${top}px`,
        transform: `scale(${scale}) translateY(${y}px) !important`
      }),
      outer: css({
        margin: '0 auto',
        width: `${width}px`
      })
    }
  }
  const { Block } = Style

  return (
    <>
      <Div
        class={css({
          backgroundImage: `url('${src() as string}')`,
          backgroundSize: 'cover',
          content: '""',
          filter: 'blur(128px)',
          height: 'inherit',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%'
        })}
      >
      </Div>
      <Div class={merge([Block.outer])}>
        <Div class={merge(['device', 'device-iphone-14-pro', Block.container])}>
          <Div class='device-frame'>
            <Div class='device-screen'>
              <img alt='' class={css({ width: '100%' })} loading='lazy' src={src()} />
            </Div>
          </Div>
          <Div class='device-stripe' />
          {
            /*
        <Div class='device-header' />
        <Div class='device-sensors' />
      */
          }
          <Div class='device-btns' />
          <Div class='device-power' />
          <Div class='device-home' />
        </Div>
      </Div>
    </>
  )
}
