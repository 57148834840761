import goober from 'goober'

export const display = {
  flex: {
    xy: {
      center: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }
    }
  },
  grid: {
    xy: {
      center: {
        display: 'grid',
        placeContent: 'center'
      }
    }
  }
}

export const position = (
  position: goober.CSSAttribute['position'],
  top: goober.CSSAttribute['top'],
  right: goober.CSSAttribute['right'],
  bottom: goober.CSSAttribute['bottom'],
  left: goober.CSSAttribute['left'],
  zIndex: goober.CSSAttribute['zIndex']
) => {
  return { bottom, left, position, right, top, zIndex }
}

export const size = (
  width: goober.CSSAttribute['width'],
  height: goober.CSSAttribute['height']
) => {
  return { height, width }
}

export const shape = (
  border: goober.CSSAttribute['border'],
  backgroundColor: goober.CSSAttribute['backgroundColor'],
  borderRadius: goober.CSSAttribute['borderRadius'],
  boxShadow: goober.CSSAttribute['boxShadow']
) => {
  return { backgroundColor, border, borderRadius, boxShadow }
}

export const font = (
  color: goober.CSSAttribute['color'],
  fontSize: goober.CSSAttribute['fontSize'],
  fontWeight: goober.CSSAttribute['fontWeight'],
  lineHeight: goober.CSSAttribute['lineHeight']
) => {
  return { color, fontSize, fontWeight, lineHeight }
}

export const clamp = (
  WebkitLineClamp: number,
  lineHeight: number
) => {
  return {
    WebkitBoxOrient: 'vertical' as goober.CSSAttribute['WebkitBoxOrient'],
    WebkitLineClamp: WebkitLineClamp as goober.CSSAttribute['WebkitLineClamp'],
    display: '-webkit-box' as goober.CSSAttribute['display'],
    height: `${lineHeight * WebkitLineClamp}px` as goober.CSSAttribute['height'],
    overflowY: 'hidden' as goober.CSSAttribute['overflowY']
  }
}

export const merge = (styles: (string | undefined)[]) => {
  return styles.filter(Boolean).join(' ')
}
