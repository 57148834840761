import { splitProps } from 'solid-js'

type Props = ComponentProps<'article'>

export function Article(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <article {...attributes}>{component.children}</article>
}
