import { splitProps } from 'solid-js'

type Props = ComponentProps<'button'>

export function Button(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <button {...attributes}>{component.children}</button>
}
