import { splitProps } from 'solid-js'

type Props = ComponentProps<'div'>

export function Div(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <div {...attributes}>{component.children}</div>
}
