import { splitProps } from 'solid-js'

type Props = ComponentProps<'span'>

export function Span(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <span {...attributes}>{component.children}</span>
}
