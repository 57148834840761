import { splitProps } from 'solid-js'

type Props = ComponentProps<'h2'>

export function H2(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <h2 {...attributes}>{component.children}</h2>
}
