import { splitProps } from 'solid-js'

type Props = ComponentProps<'p'>

export function P(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <p {...attributes}>{component.children}</p>
}
