import { splitProps } from 'solid-js'

type Props = ComponentProps<'header'>

export function Header(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <header {...attributes}>{component.children}</header>
}
