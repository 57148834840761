import { splitProps } from 'solid-js'
import { Title as SolidTitle } from 'solid-start'

type Props = ComponentProps<'title'>

export function Title(props: Props) {
  return <Presenter {...props} />
}

function Presenter(props: Props) {
  const [component, attributes] = splitProps(props, ['children'])
  return <SolidTitle {...attributes}>{component.children}</SolidTitle>
}
